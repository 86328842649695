import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutes } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { LayoutComponent } from '../app/layout/layout.component';
import { SidebarModule } from '../app/components/sidebar/sidebar.module';
import { NavbarModule } from '../app/components/navbar/navbar.module';
import { FooterModule } from 'src/app/components/footer/footer.module'; 

import { DecimalPipe, LocationStrategy, HashLocationStrategy } from '@angular/common'; 

import { AlertController } from '@ionic/angular';
import { FieldErrorDisplayModule } from 'src/app/components/field-error-display/field-error-display.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

 
import env from '../environments/environment';


const firebaseConfig = env.firebaseConfig;


@NgModule({
  declarations: [AppComponent, LayoutComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    RouterModule,
    RouterModule.forRoot(AppRoutes, { useHash: true}),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-full-width',
      closeButton: true,
    }),
    SidebarModule,
    NavbarModule,
    FooterModule,
    FieldErrorDisplayModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
  ],
  providers: [
    AlertController,
    DecimalPipe,
    StatusBar,
    SplashScreen,


    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
