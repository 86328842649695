// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export default {
  production: false,
  IN_DEVELOP: false,
  get firebaseConfig() {
    return {
      apiKey: "AIzaSyBfad6aYvirk0Ecwe0aXMKB041ef3_TUXU",
      authDomain: "caluladorate.firebaseapp.com",
      databaseURL: "https://caluladorate.firebaseio.com",
      projectId: "caluladorate",
      storageBucket: "caluladorate.appspot.com",
      messagingSenderId: "821658811241",
      appId: "1:821658811241:web:e0bce78a1eab5db5c7dbf8",
      measurementId: "G-GGM0M3XRNV",
    };
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
