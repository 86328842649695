import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ManageApplicationService } from 'src/app/services/manage-application.service';
import { Title } from '@angular/platform-browser';


@Component({
  moduleId: module.id,
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})

export class NavbarComponent implements OnInit {
  private listTitles: any = [];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private addTitles: any = [
    
    { page: '/home/configuration', name: 'Configuración de Valores', children: null },
    { page: '/home/calculator', name: 'Calculadora - IPSET', children: null },
  ];

  public isCollapsed = true;
  @ViewChild('navbar-cmp', { static: false }) button;

  constructor(location: Location,
              private renderer: Renderer2,
              private element: ElementRef,
              private router: Router,
              private mngAppService: ManageApplicationService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {

    // ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
  }
  getTitle() {
    this.listTitles = this.mngAppService.getCurrentMenu();

    if (this.listTitles === null) { return 'Calculadora - IPSET'; }
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    this.addTitles.forEach(element => {
      this.listTitles.push(element);
    });

    let result = this.listTitles.filter(item => item.page !== '/home' && titlee.indexOf(item.page) > -1 );
    if (result.length > 0) {
      return result[0].name;
    }

    result = this.listTitles.filter(item => item.children !== null && item.children.filter(x => x.page === titlee).length > 0);
    if (result.length > 0) {
      return result[0].name;
    }
    return 'Home';
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = document.getElementsByClassName('main-panel')[0] as HTMLElement;
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function() {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = document.getElementsByClassName('main-panel')[0] as HTMLElement;
    if (window.innerWidth < 991) {
      // tslint:disable-next-line:only-arrow-functions
      setTimeout(function() {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

}

