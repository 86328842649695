import { Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';



export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home/calculator',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: './layout/layout.module#LayoutModule',
      }]
  },
  {
    path: '**',
    redirectTo: 'home/calculator'
  }
];
