import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

// components
import { SidebarComponent } from './sidebar.component';
import { MenuComponent } from '../../components/menu/menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    NgbModule,
    RouterModule,
  ],
  declarations:
    [
      SidebarComponent,
      MenuComponent
    ],
  exports: [
    SidebarComponent,
    MenuComponent,
  ]
})

export class SidebarModule { }
