import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { ManageApplicationService } from 'src/app/services/manage-application.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  moduleId: module.id,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent implements OnInit {
  menu: any[];
  constructor(private toastr: ToastrService, private router: Router, private mngAppService: ManageApplicationService) {

  }

  compare(a, b) {
    const idA = a.id;
    const idB = b.id;

    let comparison = 0;
    if (idA > idB) {
      comparison = 1;
    } else if (idA < idB) {
      comparison = -1;
    }
    return comparison;
  }

  ngOnInit() {
    this.mngAppService.getMenu().then(response => {
      if (response !== null) {
        this.menu = response.sort(this.compare);
        this.mngAppService.saveMenu(response);
      } else {
        this.toastr.error('No se pudo cargar el menu en este momento. Por favor intentelo nuevamente.', 'CalculadoraApp');
      }
    }).catch(reason => {
      this.toastr.error('No se pudo cargar el menu en este momento. Por favor intentelo nuevamente.', 'CalculadoraApp');
    });
  }

  async logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.toastr.success('Sesion Finalizada', 'Autenticación');

  }

  itemSelected(event){}
}

