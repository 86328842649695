import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  timeOutQue: any = [];
  constructor(private router: Router) {
    document.onmousemove = () => { this.handlerInactive(); };
    document.ontouchstart = () => { this.handlerInactive(); };
  }

  ngOnInit() { }


  handlerInactive() {
    this.clearTimeOut();
    this.createTimeOut();
  }

  clearTimeOut() {
    this.timeOutQue.forEach(element => {
      window.clearTimeout(element);
    });
  }

  createTimeOut() {
    this.timeOutQue.push(setTimeout(() => {
      this.logout();
    }, 900000)
    );
  }


  async logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login');
    // this.messages.message('Sesion Finalizada','success');

  }

}
