import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import env from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageApplicationService {
  user: any = {};
  constructor(private http: HttpClient) { }
  public cleanStorage() {
    localStorage.clear();
  }

  public saveCurrentUser(user) {
    localStorage.setItem('CurrentUser', JSON.stringify(user));
  }

  public getCurrentUser() {
    return JSON.parse(localStorage.getItem('CurrentUser'));
  }

  public saveMenu(user) {
    localStorage.setItem('Menu', JSON.stringify(user));
  }

  public getCurrentMenu() {
    return JSON.parse(localStorage.getItem('Menu'));
  }

  public getMenu(): Promise<any> {
    const menu: any[] = [
      {
        name: 'Calculadora PBS',
        id: 1,
        page: 'https://calculadorate.azurewebsites.net/#/home/calculator',
        parent: 0,
        code: 'calculatorPBS',
        action: true
      },
      {
        name: 'Calculadora IPSET',
        id: 2,
        page: 'https://calculadoraipset.azurewebsites.net/#/home/calculator',
        parent: 0,
        code: 'calculatorIPSET',
        action: true
      },
    ];

    return Promise.resolve(menu);
  }
}
