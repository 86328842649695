import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../../models/menu-item';
import { Router } from '@angular/router';

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
};

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  @Input() menuItem: MenuItem;
  @Input() isRoot: boolean;
  @Output()
  itemSelected = new EventEmitter<MenuItem>();
  public isCollapsed = false;
  isOpen = true;

  iconName(name) {
    return toTitleCase(name);
  }

  constructor(private router: Router) {
  }

  onMenuItemSelected(menuItem: MenuItem) {
    this.itemSelected.emit(menuItem);
  }

}
