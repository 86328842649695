import { Component, OnInit } from '@angular/core';
import env from '../../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  environment: string;

  constructor() {
    
  }

  ngOnInit() {}

}
